import { FC, useContext, useState } from "react";
import {Grid, Paper, Stack, TextField, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {useLocation, useNavigate} from "react-router-dom";
import {red} from "@mui/material/colors";
import useAuth from "Hooks/useAuth";
import useToken from "Hooks/useToken";
import BackdropLoading from "Components/comm/BackDrop";
import {useError} from "Components/ErrorContext";
import Conf from "Types/Conf";
import DefaultButton from "Components/atom/Buttons/DefaultButton";
import { MessageContext } from "../MessageContext";

const LoginBox = styled(Paper)({
    textAlign: 'center',
    minWidth: '80%',
    padding: 50
});

const Login: FC = () => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const {loginRequest} = useAuth()
    const {setToken} = useToken()
    const location = useLocation()
    const navigate = useNavigate()
    const {userMessage, setContextError} = useError()
    const messageContext = useContext(MessageContext)

    const handleLogin = () => {
        messageContext.handleSetMessage('')
        setIsLoading(true)
        const res = loginRequest(email, password)
        void res?.then(value => {
                if (value.isLogin) {
                    // todo history
                    setToken(value.user.access_token)

                    const searchParams = new URLSearchParams(location.search);
                    const from = searchParams.get('from');
                    const redirectURL = from === null || from === '/login' ? '/' : from
                    navigate(redirectURL)

                } else if (value.errorMessage && value.statusCode) {
                    void setContextError(400, value.errorMessage)
                    setIsLoading(false)
                }
            }
        )
    }

    if (isLoading) return <BackdropLoading showBackdrop={isLoading}/>

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{minHeight: '90vh'}}
        >

            <LoginBox>
                <h1><img src={`${process.env.PUBLIC_URL}/passo-logo.fw.png`} height="50" alt=""/></h1>
                <Typography sx={{my: '1em'}}>{Conf.applicationTitle}ログイン</Typography>
                {userMessage ? <Typography sx={{color: red['400'], mb: '1em'}}>{userMessage}</Typography> : ""}
                <form onSubmit={handleLogin}>
                    <Stack spacing={4}>
                        <TextField required label="メールアドレス" type="email" value={email} aria-label="email"
                                   onChange={event => setEmail(event.target.value)} autoFocus/>
                        <TextField required label="パスワード" autoComplete="off" type="password" value={password} id="password"
                                   onChange={event => setPassword(event.target.value)}/>
                        <DefaultButton name="ログイン" label="ログイン" onClick={() => handleLogin()} type="submit" disabled={false}/>
                    </Stack>
                </form>
            </LoginBox>
        </Grid>
    )

}
export default Login
