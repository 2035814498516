import React, { Dispatch, FC, ReactNode, SetStateAction, useState } from "react";
import { Alert, Box, Grid, Stack, TextField, Typography } from "@mui/material";
import StudentInfo from "Components/comm/User/StudentInfo";
import DefaultButton from "Components/atom/Buttons/DefaultButton";
import SecondButton from "Components/atom/Buttons/SecondButton";
import { TClassStudent } from 'Types/TStudent';
import ThirdButton from "../atom/Buttons/ThirdButton";

type Prop = {
    isReserve:boolean,
    onBook:(studentId:number)=>void,
    cancelBook:(bookId:number)=>void,
    students: TClassStudent[],
    studentsUpdate: Dispatch<SetStateAction<TClassStudent[]>>
}
const StudentReserve: FC<Prop> = ({students, studentsUpdate,onBook,cancelBook,isReserve}) => {
    const [rUser, setRUser] = useState<TClassStudent[]>(students)
    const [keyword, setKeyword] = useState<string>("");
    const handleClear = () => {
        setKeyword("")
        studentsUpdate(rUser)

    }
    const handleSearch = () => {
        if (rUser.length === 0) setRUser(students)
        // console.log(keyword.length)
        if (keyword.length > 0) {
            const nUsers = students.filter(value => String(value.name).toLowerCase().indexOf(keyword) > -1)

            studentsUpdate(nUsers)
        } else {
            studentsUpdate(rUser)
        }
    }
    const createReserveViewButton=(student:TClassStudent):ReactNode=>{
        const reserveButton= <DefaultButton label="予約済みにする" onClick={()=>{onBook(student.id)} } />
        const cancelButton= <ThirdButton label="キャンセル" onClick={()=>{cancelBook(student.book_id ? student.book_id : 0)} } />

      if(student.infoLabel==="予約済み" || (student.infoLabel==="キャンセル待ち" && !isReserve)) return cancelButton

      if(student.infoLabel==="キャンセル待ち"){
          return <Grid container  direction="row" spacing={1}>
              <Grid item md={6} xs={12}>{reserveButton}</Grid>
              <Grid item md={6} xs={12}>{cancelButton}</Grid>

          </Grid>
              }

      return <DefaultButton label="予約する" onClick={()=>{onBook(student.id)} } />

    }

      const createInfoElement=(student:TClassStudent):ReactNode=> <><Typography sx={{my:2}}>{student.infoLabel}</Typography>{createReserveViewButton(student)}</>

    return (
        <>
            <Box sx={{marginY: '1em'}}>
                <Grid container spacing={2} sx={{marginBottom: '2em'}} justifyItems="center" justifyContent="flex-end">
                    <Grid item xs={12} md={6}>
                        <TextField placeholder="名前" sx={{width:'100%'}} type="text" value={keyword} inputProps={{'aria-label':"keyword"}}
                                        onChange={event => setKeyword(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                <Stack justifyContent={{'xs':'center', 'md':'left'}} direction="row" alignItems="center" spacing={3}>
                    <DefaultButton name="search" onClick={() => handleSearch()} label="検索 "/>
                    <SecondButton name="clear" onClick={() => handleClear()} label="クリア"/>

                </Stack>
                </Grid>
                </Grid>
            </Box>
            {students?.length === 0 && (
                <Alert aria-label="not Found" sx={{mt: 2, mb: 4}} severity="info">利用者が見つかりませんでした。
                </Alert>
            )}

            <Box sx={{flexGrow: 1, my: 2}}>
                <Grid container direction="row" spacing={1}>
                    {students?.map((student) => (
                        <Grid item xs={12} md={4} key={student.id}>

                            <StudentInfo
                              student={student}
                              content={createInfoElement(student)} />
                        </Grid>

                        // </Link> |</p>
                    ))}
                </Grid>
            </Box>
        </>
    )
}
StudentReserve.defaultProps = {
}
export default StudentReserve;
