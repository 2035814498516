import React, {FC, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {Grid, Stack, Table, TableBody, TableRow, Typography} from "@mui/material";
import {useLocation} from "react-router-dom";
import DefaultButton from "../atom/Buttons/DefaultButton";
import Box from "../atom/Box";
import useFetchStudentBook, {ICurriculumBook} from "../../Hooks/useFetchStudentBook";
import BackdropLording from "../comm/BackDrop";
import CellDay from "../atom/CellDay";
import CellDateHead from "../comm/CellDateHead";

type IBookDetailParam = {
    id: string
    year:string
    month:string
}
type counts={
    booking:number,
    cancel:number
}


const StudentBooks: FC = () => {
    const {id,year,month} = useParams() as IBookDetailParam
    const [calendarDate] = useState<Date>(new Date(Number(year),Number(month)-1,1))
    const [counter,setCounter] = useState<counts>({booking:0,cancel:0})
    const navigate = useNavigate()
    const location = useLocation()

    const {fetchRequest,isLoading,curriculums,studentName,weeks}= useFetchStudentBook()
    const weekDay = ['one', 'two', 'three', 'four', 'five', 'six']
    const handleClick = (curriculumBook:ICurriculumBook) => {
        if(curriculumBook.lectureName !== '休日') navigate(`/book/${curriculumBook.id}`,{state:location})
    }
    const handlePointer = (curriculumBook:ICurriculumBook) => {
        if(curriculumBook.lectureName === '休日') return 'nomal'

        return 'pointer'
    }
    const handleCalendarDown = () =>{
        calendarDate.setMonth(calendarDate.getMonth()-1)
        navigate(`/student/books/${id}/${calendarDate.getFullYear()}/${calendarDate.getMonth() + 1}`)
    }
    const handleCalendarUP = () => {
        calendarDate.setMonth(calendarDate.getMonth()+1)
        navigate(`/student/books/${id}/${calendarDate.getFullYear()}/${calendarDate.getMonth() + 1}`)
    }

    const cellColor = (status:string) => {
        if(status=== '予約済み') return 11
        if(status.includes('キャンセル待ち')) return 8

        return 0
    }
    useEffect(() => {
        fetchRequest(id,year,month)

        // eslint-disable-next-line
    },[id, year, month])

    useEffect(() => {
        setCounter({
            booking: curriculums.filter(value => value.bookStatus === '予約済み').length,
            cancel: curriculums.filter(value => value.bookStatus.includes('キャンセル待ち')).length,
        })
    },[curriculums])


    if (isLoading) return <><BackdropLording showBackdrop={isLoading}/></>

    return <>
        <Box sx={{my: 3}}>
            <Grid container>
                <Grid item xs={12} sm={8}>
                    <Typography variant="h5" sx={{textAlign:{xs:'center',md:'left',pb:{xs:'10rem',md:0}}}}>
                        {calendarDate.getFullYear()}年{Number(calendarDate.getMonth()) + 1}月 {studentName}様予約状況
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} justifyContent="center">
                    <Stack justifyContent={{xs:'center',md:'flex-end'}} direction="row" alignItems="right"
                           spacing={3}>
                        <DefaultButton name="preMonth" onClick={handleCalendarDown} label="前月"/>

                        <DefaultButton name="nextMonth" onClick={handleCalendarUP} label="翌月"/>
                    </Stack>
                </Grid>
            </Grid>
            <Typography variant="h6" sx={{my:2,textAlign:'right'}}>予約数:{counter.booking}<br/>キャンセル待ち数:{counter.cancel}</Typography>
        <Table sx={{my:2,width: '100%', border: '1px solid #eee', borderSpacing: '0'}}>
            <TableBody>
            {weeks.map((week, key) =>
                <TableRow key={weekDay[key]}>
                    {week.map((day) =>(
                        <CellDay
                            key={`${day.lectureDate.getFullYear()}${day.lectureDate.getMonth()}${day.lectureDate.getDate()}`}
                            uniqueKey={`${day.lectureDate.getFullYear()}${day.lectureDate.getMonth()}${day.lectureDate.getDate()}`}
                            isMobileDisplay={calendarDate.getFullYear() === day.lectureDate.getFullYear() && calendarDate.getMonth() === day.lectureDate.getMonth()} bookCount={cellColor(day.bookStatus)}>
                            <Grid sx={{cursor:handlePointer(day)}} onClick={() => handleClick(day)}>
                            <CellDateHead date={day.lectureDate}/>
                            <Typography>{day.lectureName}</Typography>
                            <Typography sx={{my:1}}>{day.bookStatus}</Typography>
                            <Typography>{day.bookTime}</Typography>

                            </Grid>
                        </CellDay>
                    ))}
                </TableRow>
            )}
                </TableBody>
        </Table>
        </Box>
        </>
};

export default StudentBooks
