import { useState } from "react";
import {AxiosError} from 'axios';
import {ICurriculumDetail, ICurriculumDetailResponse} from '../Types/Response';
import useHttp from "./useHttp";
import useHandleError from "./useHandleError";

type IhandlePersonAddErrorRespons  = AxiosError & {response:{data:{errors: {curriculum_id?: string[]}}}}
const useCurriculumDetail = () => {
    const [response, setResponse] = useState<ICurriculumDetail>({
        curriculum: undefined,
        onStudents: [],
        cancelStudents:[],
        students: [],
        error: null,
        isLoading: false,
        systemMessage: undefined
    })
    const [curriculumId, setCurriculumId] = useState<string>("0")
    const {client} = useHttp()
    const {handleError} = useHandleError()


    const fetchRequest = (id: string) => {
        setResponse(prevState => ({...prevState, isLoading: true}))
        const c = client()
        c.get<ICurriculumDetailResponse | null>(`curriculums/detail/${id}`)
            .then((res) => {
                const serviceLimit = res.data?.curriculum ? res.data.curriculum.service_limit : 0

                setResponse({
                    curriculum: res.data?.curriculum,
                    onStudents: res.data?.onStudents ? res.data.onStudents.filter(s => Number(s.book_rank) <= serviceLimit) : [],
                    cancelStudents : res.data?.onStudents ? res.data.onStudents.filter(s => Number(s.book_rank) > serviceLimit) : [],
                    students: res.data?.students ? res.data.students : [],
                    error: null,
                    isLoading: false,
                    systemMessage: {text: "予約状況を読み込みました。", isDelete: true}
                })

            })
            .catch((error: AxiosError) => {
                setResponse(prevState => ({...prevState, isLoading:false}))
                handleError(error)

           })
    }
    const fetchRequestDownload = (id: string) => {
        setResponse(prevState => ({...prevState, isLoading: true}))
        const c = client()
        c.post<ICurriculumDetailResponse | null>(`curriculums/sendDailyList`, {curriculum_id: Number(id)})
            .then(() => {
                setResponse(prevState => ({
                    ...prevState,
                    isLoading: false,
                    systemMessage: {text: "予約状況を送信いたしました。", isDelete: true}
                }))

            })
            .catch((error: AxiosError) => {
                setResponse(prevState => ({...prevState, isLoading:false}))
                handleError(error)

            })
    }

    const handlePersonCancel = (bookId: number) => {
        setResponse(prevState => ({...prevState, isLoading: true}))
        const c = client()
        c.delete<ICurriculumDetailResponse | null>(`curriculums/cancel/${bookId}`)
            .then((res) => {
                const serviceLimit = res.data?.curriculum ? res.data.curriculum.service_limit : 0
                setResponse((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    onStudents: res.data?.onStudents ? res.data.onStudents.filter(s => Number(s.book_rank) <= serviceLimit) : [],
                    cancelStudents : res.data?.onStudents ? res.data.onStudents.filter(s => Number(s.book_rank) > serviceLimit) : [],
                    students: res.data?.students ? res.data.students : [],
                    systemMessage: {text: "予約をキャンセルしました。", isDelete: true}
                }))
            })
            .catch((error: AxiosError) => {
                setResponse(prevState => ({...prevState, isLoading:false}))
                handleError(error)

            })

    }
    const handlePersonAdd = (userId: number) => {
        setResponse(prevState => ({...prevState, isLoading: true, systemMessage: undefined}))
        const c = client()
        c.post<ICurriculumDetailResponse | null>('curriculums/attend', {
            'user_id': userId,
            curriculum_id: curriculumId
        })
            .then((res) => {
                const serviceLimit = res.data?.curriculum ? res.data.curriculum.service_limit : 0
                    setResponse((prevState) => ({
                        ...prevState,
                        isLoading: false,
                        onStudents: res.data?.onStudents ? res.data.onStudents.filter(s => Number(s.book_rank) <= serviceLimit) : [],
                        cancelStudents : res.data?.onStudents ? res.data.onStudents.filter(s => Number(s.book_rank) > serviceLimit) : [],
                        students: res.data?.students ? res.data.students : [],
                        systemMessage: {text: "予約を追加しました。", isDelete: true}
                    }))
                }
            )
            .catch((error: IhandlePersonAddErrorRespons) => {
                if(error.response?.status === 400){

                  setResponse(prevState => ({...prevState, systemMessage: {text:error.response.data.errors.curriculum_id ? error.response.data.errors.curriculum_id[0] : "予約ができませんでした。", isDelete: true}}))
                }

                setResponse(prevState => ({...prevState, isLoading:false}))
                handleError(error)

            })

    }

    return {
        ...response,
        setResponse,
        fetchRequest,
        fetchRequestDownload,
        setCurriculumId,
        handlePersonCancel,
        handlePersonAdd
    }
};
export default useCurriculumDetail;
