import { FC } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Typography } from "@mui/material";
import { IgraphCurriculum } from "../../Hooks/useFetchHome";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type props = {
  curriculums: IgraphCurriculum[]
}
const DailyBar: FC<props> = ({ curriculums }) => {

  if (!curriculums) return <Typography variant="h5">予約がありません</Typography>;

  return <>

    {curriculums.map((curriculum) =>
      <>
        <Typography variant="h5">{curriculum.title}</Typography>
        <Bar height="50" data={{
          labels: curriculum.curriculum.map((c, i) => (i + 1).toString()),
          datasets: [{
            label: "予約数",
            data: curriculum.curriculum.map((c) => c.books.length),
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192)",
            borderWidth: 1
          }]
        }} /></>)}</>;
};
export default DailyBar;
